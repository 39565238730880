body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efe8e1;
}

.App {
  padding: 0 0;
  display: grid;
  grid: 4fr 10fr 1fr / 1fr;
}

.App-header {
  display: flex;
  flex-direction: column;
}

header {
  grid-row: 1 / 2;
  background-color: #efe3d6;
  padding: 1% 4%;
}

header h2 {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.578);
  margin-top: 0;
  margin-bottom: 2%;
}

main {
  grid-row: 2 / 3;
  padding: 25px 25px;
}

footer {
  grid-row: 3 / 4;
  padding: 0 50px;
}

nav {
  display: flex;
  flex-direction: row;
}

nav .link {
  margin: 0 5%;
  background-color: #c77a67;
  padding: 5% 10%;
  border-radius: 10px;
  color: white;
  text-decoration: none;
}

nav .link:hover {
  background-color: #D98C77;
  color: white;
}

nav .link:active {
  background-color: #e7ac9b;    
}

#about {
  display: grid;
  grid: 1fr / 3fr 1fr;
  height: 100%;
  gap: 3%;
  padding: 0 3%;
}

p, li {
  font-size: 1.1rem;
}

#about img {
  max-height: 510px;
}

#about section, #research, #teaching  {
  background-color: #eae3dc;
  padding: 5px 3%;
  height: 100%;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-content: space-evenly;
}

#teaching h3 {
  text-align: center;
  font-size: 1.25rem;
}

#teaching h4 {
  font-weight: 450;
  font-style: italic;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.594);
}

#courseContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  font-size: 1.15rem;
}

#publications li {
  margin: 2% 0;
}

#publications a {
  margin: 0 1%;
}

a {
  color: #3e7d8f;
}

a:hover {
  color: #78b4c4;
}
